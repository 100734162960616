var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "spinner"
  }, [_c('div', {
    staticClass: "spinner-item"
  }), _c('div', {
    staticClass: "spinner-item"
  }), _c('div', {
    staticClass: "spinner-item"
  }), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.50165 14.813C2.50165 14.1917 3.01375 13.688 3.64545 13.688H7.59903C8.14693 13.688 8.61787 14.0701 8.72199 14.5992L10.6885 24.5926C10.9283 25.3713 11.3992 25.6778 11.8821 25.6778H26.0378C26.0452 25.6755 26.1457 25.6545 26.3153 25.4975C26.5051 25.3218 26.7333 25.0146 26.9253 24.5388L29.0479 14.5821C29.159 14.0611 29.6261 13.688 30.1674 13.688H33.9562C34.5879 13.688 35.1 14.1917 35.1 14.813C35.1 15.4343 34.5879 15.938 33.9562 15.938H31.0962L29.1453 25.0894C29.1334 25.1452 29.1172 25.2001 29.0968 25.2535C28.8004 26.0308 28.3836 26.6726 27.8831 27.136C27.3934 27.5893 26.7562 27.9278 26.049 27.9278H11.8821C10.0675 27.9278 8.8995 26.6254 8.47602 25.1676C8.46689 25.1362 8.45916 25.1044 8.45285 25.0723L6.65538 15.938H3.64545C3.01375 15.938 2.50165 15.4343 2.50165 14.813Z",
      "fill": "#455472"
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };